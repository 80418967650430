import { addCallback, setGlobal } from 'reactn';

const storageKey = "_global";
let timeout = null;
let lastGlobal = null;
let lastSave = null;
const debounce = 1000;

function globalStateChange(global) {
  if(lastSave === null || Date.now()-lastSave > debounce) {
    lastSave = Date.now();
    if(timeout) { clearTimeout(timeout); timeout = null; }
    saveState(global || lastGlobal);
  } else {
    lastGlobal = global;
    if(timeout === null) {
      timeout = setTimeout(() => globalStateChange(null),
                           debounce-(Date.now()-lastSave)+1);
    }
  }
}

function saveState(state) {
  let snapshot = {};
  state.persist.forEach(field => snapshot[field] = state[field]);
  localStorage.setItem(storageKey, JSON.stringify(snapshot));
}

export function initPersist(initialState) {
  const savedGlobal = JSON.parse(localStorage.getItem(storageKey) || '{}');
  setGlobal({ ...initialState, ...savedGlobal });
  addCallback(global => globalStateChange(global));
}

export function clearPersistedState() {
  localStorage.removeItem(storageKey);
}