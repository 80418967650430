import React from 'react';
import autoBind from 'react-autobind';
import { COLORS } from './colors';

export function speakerStyle(spid) {
  if(spid === null || spid === undefined) return {};
  const { bg, fg } = spid < 0 ? { bg:'#fff', fg:'#000' }
                     : spid === 1 ? { bg:'#777', fg:'#fff' }
                     : COLORS[spid % COLORS.length];
  return { background: bg, color: fg };
}

export class SpeakerLabel extends React.PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    const { spid, changing, nickname } = this.props;
    const name = (nickname||"").substr(0,2);
    return <div className={"speaker-tag"
                           + (changing ? ' changing-speaker':'')}
                style={speakerStyle(spid)}
                onClick={this.props.onClick}
                ><span>{name}</span></div>
  }

};