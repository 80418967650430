import React from 'reactn';
import autoBind from 'react-autobind';
import { isEmpty, objWithout } from './utils';
import './style.css';
import { ParagraphRow } from './ParagraphRow';
import Window from './Window';
import Header from './header';
import SpeakerFilters from './SpeakerFilters';
import ChapterFilters from './ChapterFilters';

class Book extends React.PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.retryWritesInterval = null;
  }
  componentWillUnmount() {
    window.removeEventListener('keyup', this.onGlobalKeyPress);
    window.removeEventListener('keydown', this.onGlobalKeyDown);
    document.getElementById("book").removeEventListener('scroll', this.onScroll);
    if(this.retryWritesInterval) clearInterval(this.retryWritesInterval);
  }

  componentDidMount() {
    document.title = this.global.title.he + ' - ספרא';
    window.scrollTo(0,0);
    this.maybeActivateRewritesInterval();
    window.addEventListener('keyup', this.onGlobalKeyPress);
    window.addEventListener('keydown', this.onGlobalKeyDown);
    document.getElementById("book").addEventListener('scroll', this.onScroll, {passive: true});
    //console.log(document.getElementById("book").contentHeight);
    this.dispatch.resetFilters(); // so that Window can cache all element heights..
  }

  componentDidUpdate() {
    this.maybeActivateRewritesInterval();
  }

  maybeActivateRewritesInterval() {
    if(this.global.areNetworkWritesPending && !this.retryWritesInterval) {
      this.retryWritesInterval = setInterval(this.retryWrites, 5000);
    }
  }

  retryWrites() {
    const pending = Object.values(this.global.paragraphs).filter(p => p.dirty);
    if(pending.length > 0) {
      pending.forEach(p => this.dispatch.saveParagraphEdit(p.id, p.str, true));
    } else {
      this.setGlobal({areNetworkWritesPending: false});
      clearInterval(this.retryWritesInterval);
      this.retryWritesInterval = null;
    }
  }

  onGlobalKeyPress(e)  {
    if(!e) return;
    const key = e.key;
    if(key === 'Tab') {
      window.scrollTo(0,0);
      document.getElementById("search-box").focus();
    }
  }

  onGlobalKeyDown(e)  {
    if(!e) return;
    const key = e.key;
    if(key === 'Escape') {
      if(this.global.editing) {
        this.setGlobal({editing: null});
      } else {
        this.dispatch.resetFilters();
        if(!isEmpty(this.global.changingSpeaker)) {
          this.setGlobal({changingSpeaker: {}});
        }
      }
    }
    if(!this.global.editing && 
       (key === 'PageUp' || key === 'PageDown'
        || key === 'ArrowDown' || key === 'ArrowUp')) {
      document.getElementById("book-inner").focus();
    }
  }

  onScroll(e) {
    //if(!this.global.editing) document.getElementById("book").focus();
  }

  onParagraphStartEdit(id) {
    this.setGlobal({ editing: id, changingSpeaker: {} });
  }

  onParagraphCancelEdit() {
    this.setGlobal({editing: null});
  }

  onParagraphSaveEdit(id, str) {
    this.dispatch.saveParagraphEdit(id, str);
    this.dispatch.updateMatchCount();
  }

  onSpeakerLabelClicked(seq) {
    if(this.global.changingSpeaker[seq]) {
      this.setGlobal({changingSpeaker: objWithout(this.global.changingSpeaker, seq)});
    } else {
      this.setGlobal({changingSpeaker: {
                      ...this.global.changingSpeaker,
                      [seq]: true}});
    }
  }

  onTextSelection(pid, text) {
    this.dispatch.addGlobalMarker(text);
  }

  onMarkerClick(pid, text) {
    this.dispatch.removeGlobalMarker(text);
  }

  paragraphById(id) {
    return this.global.paragraphs[id];
  }

  renderRow(reportHeight, { id, idx, ids}) {
    const g = this.global;
    const po = g.paragraphOrder;
    const paras = po[id].map(i => g.paragraphs[i]);
    const isFirst = idx === 0;
    const isLast = idx === ids.length-1;
    const prevIdx = id>0 && (idx===0 || ids[idx-1]!==id-1) ? id-1 : null;
    const nextIdx = id<po.length-1 && (idx===ids.length || ids[idx+1]!=id+1) ? id+1 : null;
    const prevp = idx > 0 ? g.paragraphs[po[ids[idx-1]][0]] : null;
    return <ParagraphRow key={paras[0].id}
      isLast={isLast}
      isFirst={isFirst}
      reportHeight={reportHeight}
      paras={paras}
      markers={g.markers}
      textFilter={g.filters.text}
      chapterFilter={g.filters.chapter}
      editing={g.editing}
      needsWork={paras[0].needs_work || paras[1].needs_work}
      isPerfect={paras[0].is_perfect || paras[1].is_perfect}
      onStartEdit={this.onParagraphStartEdit}
      onCancelEdit={this.onParagraphCancelEdit}
      onSaveEdit={this.onParagraphSaveEdit}
      onTextSelection={this.onTextSelection}
      onMarkerClick={this.onMarkerClick}
      speakerNickname={paras[0].speaker ? g.speakers[paras[0].speaker].nickname : ""}
      onSpeakerClicked={this.onSpeakerLabelClicked}
      onToggleNeedsWork={p => this.dispatch.toggleNeedsWork(p.id)}
      onToggleIsPerfect={p => this.dispatch.toggleIsPerfect(p.id)}
      changingSpeaker={g.changingSpeaker[paras[0].seq]}
      chapter={g.chapters[paras[0].chapterId] || { "ru": '?', "he": '?' } }
      showChapterTitle={!prevp || prevp.chapterId != paras[0].chapterId}
      revealPrevious={prevIdx===null?null:()=>this.dispatch.expandMatches(prevIdx)}
      revealNext={nextIdx===null?null:()=>this.dispatch.expandMatches(nextIdx)}
      readOnly={g.auth?.readOnly}
      seqProgress={paras[0].cumnumchars
                   / g.paragraphs[po[po.length-1][0]].cumnumchars}
    />
  }

  render() {
    /*
    function download(content, fileName, contentType) {
    var a = document.createElement("a");
    var file = new Blob([content], {type: contentType});
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
}
download(jsonData, 'json.txt', 'text/plain');
    */
    const g = this.global;
    const saving = <div className="saving"><span>Saving...</span></div>;
    let classes = ["wrapper"];
    if(g.editing) classes.push('editing');
    if(g.paragraphOrder.length !== g.matches.counts.paragraphs) classes.push('is-filtered');
    if(!isEmpty(g.changingSpeaker)) classes.push('is-changing-speaker');
    if(g.areNetworkWritesPending) classes.push('network-writes-pending');
    return <div className={classes.join(" ")}>
      {g.saving ? saving : null}
      <Header />
      <div className="content">
        <ChapterFilters/>
        <SpeakerFilters/>
        <div className="book-wrapper">
          <div id="book" tabIndex="2">
            <Window ids={g.matches.paragraphs}
                    renderItem={this.renderRow}
                    editing={g.editing} />
          </div>
        </div>
      </div>
      
    </div>
  }
}

export default Book;