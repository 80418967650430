import React from 'react';

export default function FilterTagList({items, nameFunc, styleFunc, classFunc,
                                       statsFunc, isSelectedFunc, onClick}) {
  return items.map(item => {
    const sel = isSelectedFunc(item);
    const count = statsFunc(item) || (sel?0:"");
    //const cls = count > 0 ? (sel?'selected-filter-tag':''):'notselected-filter-tag';
    const cls = sel?'selected-filter-tag':count > 0 ? '' : 'notselected-filter-tag';

    const style     = styleFunc ? styleFunc(item) : {};
    const className = classFunc ? classFunc(item) : ""; 
    const cspan = <span className="stat-count">{count}</span>;
    const nspan = <span className="stat-name"
                        style={style}>{nameFunc(item)}</span>

    return <div key={item} className={`filter-tag ${className} ${cls}`}
                onClick={()=>onClick(item)}>{nspan}{cspan}</div>;
  })
}
