import React from 'reactn';
import autoBind from 'react-autobind';
import Book from './Book';
import LoginScreen from './login';
import Loader from './loader';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.loadBookList();
  }

  loadBookList() {
    if(this.global.auth && !this.global.loadingBookList) {
      this.dispatch.listBooks();;
    }
  }

  onLogin(token) {
    this.dispatch.onLogin(token);
    this.loadBookList();
  }

  render() {
    if(!this.global.auth) {
      return <LoginScreen onLogin={token => this.onLogin(token)}/>
    }
    if(this.global.loadingBook || this.global.loadingBookList) {
      return <Loader/>
    }
    if(this.global.bookLoaded === 0) {
      const style= {
        "padding": '1rem', "borderRadius": '1rem', "background": '#eee',
        "cursor": "pointer", "margin": "1rem", "textAlign": "center", "width":'90%',
        "display": "inline-block", "border":"1px solid #555"
      };
      return <div style={{"textAlign":"center"}}>{
        this.global.bookList.map(({book,he,ru}) => <div key={book} style={style}  onClick={()=>this.dispatch.loadBook(book)}>{he} | {ru}</div>)
      }</div>
    }
    return <Book id={this.global.bookLoaded} />
  }
};

