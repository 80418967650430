import React from 'react';
import autoBind from 'react-autobind';
import { fetchJson } from './network';
import { clearPersistedState } from './persist';
import './login.css';
import Logo from './safra_opt.svg';

export default class LoginScreen extends React.PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { loginError: false, loginSuccess: false };
  }

  componentDidMount() {
    clearPersistedState();
  }
  
  onSubmit(event) {
    event.preventDefault();
    this.username.blur();
    this.password.blur();
    fetchJson('/a', { c: 'login',
                         username: this.username.value,
                         password: this.password.value },
              json => {
                this.setState({loginSuccess: true});
                setTimeout(() => this.props.onLogin(json), 600);
              },
              error => this.setState({loginError: true, loginSuccess: false}));
  }

  inputFocused() {
    if(this.state.loginError) this.setState({loginError: false});
  }

  render() {
    const cls = (this.state.loginError ? ' login-error' : '')
                + (this.state.loginSuccess ? ' login-success' : '');
    return <div className="login">
    <div>
      <span><img src={Logo} width="140px" style={{opacity:0.7}} /></span>
      <div><form onSubmit={this.onSubmit}>
        <input type="password" style={{display:'none'}}/>
        <div><input type="text"     ref={x=>this.username=x}
                 onFocus={this.inputFocused}/></div>
        <div><input type="password" ref={x=>this.password=x}
                 onFocus={this.inputFocused}/></div>
        <input type="submit" value="" className={cls} />
        </form>
      </div>
      <div style={{marginTop: 40}}>SAFRA.CC by <a href="https://yanivhamo.com/" style={{color: 'black'}}>Yaniv Hamo</a></div>
    </div></div>;
  }
};