import React from 'reactn';
import autoBind from 'react-autobind';
import FilterTagList from './FilterTagList';
import { speakerStyle } from './Speaker';
import { isEmpty } from './utils';

export default class SpeakerFilters extends React.PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  onSpeakerButtonPress(spid) {
    if(isEmpty(this.global.changingSpeaker)) {
      this.dispatch.toggleFilter('speaker', spid);
    } else {
      this.dispatch.changeSpeaker(Object.keys(this.global.changingSpeaker), spid);
    }
  }

  renderSpeakersFilterList() {
    const g = this.global;
    return <FilterTagList items={g.speakerOrder}
               nameFunc={id => g.speakers[id].nickname}
               styleFunc={id => {
                                 let style = speakerStyle(id);
                                 if(g.matches.counts.speaker[id]===0) {
                                   delete style.background;
                                   delete style.color;
                                 }
                                 return style;
                               }}
               statsFunc={id => g.matches.counts.speaker[id]}
               isSelectedFunc={id => g.filters.speaker[id]}
               onClick={this.onSpeakerButtonPress} />
  }

  render() {
    return <div className="filter-tag-list filter-speakers">
          {this.renderSpeakersFilterList()}
          <footer>
             (c) 2020 <a href="https://yanivhamo.com/">Yaniv Hamo</a>
          </footer>
        </div>
  }
}