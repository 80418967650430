import React from 'react';
import autoBind from 'react-autobind';



class Window extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = { width: window.innerWidth, firstIndex: -1 };
    this.elementHeight_ = {};
    this.totalHeight_ = 0;
    this.wrapperDiv_ = null;
    this.lastScrollPosition_ = {};
    this.hash_ = null;
    this.resizeBounce_ = null;
    this.scrollBounce_ = null;
    this.lastScrollData_ = null;
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    window.addEventListener('recalcwindow', this.onRecalc);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('recalcwindow', this.onRecalc);
  }

  componentDidUpdate(prevProps) {
    this.hash_ = this.props.ids.join(",");
    if(this.props.ids !== prevProps.ids || this.state.firstIndex < 0) {
      this.lastScrollData_ = { firstIndex: 0, totalHeight: 0 };
      if(this.hash_ in this.lastScrollPosition_) {
        this.wrapperDiv_.scrollTo(0, this.lastScrollPosition_[this.hash_]);
      } else if(this.props.ids.length>0 && prevProps.ids.length>0 &&
                this.props.ids[0] !== prevProps.ids[0]) {
        this.wrapperDiv_.scrollTo(0,0);
      }
      this.onScroll({bounce:false});
    }
  }

  onScroll({bounce}) {
    if(this.scrollBounce_) clearTimeout(this.scrollBounce_);
    if(bounce) {
      this.scrollBounce_ = setTimeout(() => this.updateFirstIndex(), 30);
    } else {
      this.updateFirstIndex();
    }
  }

  updateFirstIndex() {
    const sd        = this.lastScrollData_;
    const scrollTop = this.wrapperDiv_.scrollTop;
    const hf        = idx => this.elementHeight_[this.props.ids[idx]];
    const n         = this.props.ids.length;
    let   delta     = scrollTop - sd.totalHeight;
    const dir = delta > 0 ? 'down' : 'up';
console.log({sd, delta, scrollTop});
    while(dir === 'down' && sd.firstIndex < n-1 && delta > hf(sd.firstIndex)) {
      const h = hf(sd.firstIndex++);
      delta          -= h;
      sd.totalHeight += h;
    }
    while(dir === 'up' && sd.firstIndex > 0 && -delta >= -hf(sd.firstIndex-1)) {
      const h = hf(--sd.firstIndex);
      delta          += h;
      sd.totalHeight -= h;
    }

    if(this.hash_) this.lastScrollPosition_[this.hash_] = scrollTop;
    if(sd.firstIndex !== this.state.firstIndex) {
      this.setState({ firstIndex: sd.firstIndex });
    }
  }

  onResize() {
    this.lastScrollPosition_ = {};
    if(this.resizeBounce_) clearTimeout(this.resizeBounce_);
    if(window.innerWidth !== this.state.width) {
      this.resizeBounce_ = setTimeout(() => {
        this.resizeBounce_ = null;
        this.onRecalc();
      }, 200);
    }
  }

  onRecalc() {
   // this.lastScrollData_.firstIndex = 0;
   // this.lastScrollData_.totalHeight = 0;
    this.setState({firstIndex: -1, width: window.innerWidth });
  }

  render() {
    let ids = [];
    let paddingTop = 0;
    let paddingBottom = 0;
    if(this.state.firstIndex < 0) ids = this.props.ids.map((id, idx) => ({id,idx}));
    else {
      const windowHeight = window.innerHeight;
      let height = 0;
      const eh = id => this.elementHeight_[id] || 0;
      this.props.ids.forEach((id, i) => {
        const elHeight = eh(id);
        if(i < this.state.firstIndex) { paddingTop += elHeight; return; }
        if(i == this.state.firstIndex) {
          // go backward and collect another windowHeight of items
          let backHeight = 0;
          for(let j=this.state.firstIndex-1; j>=0 && backHeight<windowHeight; --j) {
            const jid = this.props.ids[j];
            const jHeight = eh(jid);
            backHeight += jHeight;
            paddingTop -= jHeight;
            ids.unshift({id: jid, idx: j})
          }
        }
        if(i >= this.state.firstIndex && height < 2*windowHeight) {
          ids.push({id, idx: i});
          // We only start counting height AFTER the first element, assuming that
          // it is only barely visible on the page:
          if(i > this.state.firstIndex) height += elHeight;
        } else {
          paddingBottom += elHeight;
        }
      });
    }
    return <div style={{ height: '100%', overflowY: 'scroll' }}
                onScroll={e => this.onScroll({bounce:true})}
                tabIndex={3} ref={el => this.wrapperDiv_ = el}
                id="book-inner">
            <div style={{paddingTop}}></div>
             {ids.map(({id, idx}) => this.props.renderItem(
                                  height => this.elementHeight_[id]=height,
                                  { id, idx, ids: this.props.ids }))}
            <div style={{paddingBottom}}></div>
          </div>
  }
}

export default Window;