import React from 'react';
import autoBind from 'react-autobind';
import { SpeakerLabel } from './Speaker';
import { Paragraph, parseParagraph } from './Paragraph';
import { isEmpty } from './utils';

export class ParagraphRow extends React.PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.rowDiv_ = null;
    this.chapterRowDiv_ = null;
  }

  componentDidMount() { this.reportHeight(); }
  componentDidUpdate() { this.reportHeight(); }
  reportHeight() {
    let height = this.rowDiv_ ? this.rowDiv_.offsetHeight : 0;
    if(this.chapterRowDiv_) height += this.chapterRowDiv_.offsetHeight;
    if(this.props.reportHeight) this.props.reportHeight(height);
  }

  renderChapterTitleRow(c, id) {
//    if(!isEmpty(this.props.chapterFilter) && !(id in this.props.chapterFilter)) return null;
    if(!c) return null;
    return <section key={`chapter${id}`} className={"row " + (this.props.isFirst?'first-row':'')}
                ref={el => this.chapterRowDiv_ = el}>
      <div className="left-column chapter-title lang-ru">{c.ru}</div>
      <div className="middle-column"></div>
      <div className="right-column chapter-title lang-he">{c.he}</div>
    </section>
  }

  renderParagraph(p, cls) {
    const dirty = p.dirty ? "dirty" : "";
    let html = p.html;
    if(this.props.textFilter) {
      const tp = parseParagraph(p, this.props.textFilter);
      if(html !== tp.html) html = tp.html;
    } else if(this.props.markers && !p.is_perfect) {
      this.props.markers.forEach((x, i) => {
        const re = new RegExp(x.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
                              + '(?![^<]*>)', "gi");
        //const { bg, fg } = BRIGHTCOLORS[i % BRIGHTCOLORS.length];
        const bg = `hsl(${(i*25)%240}, 100%, ${50 + 40*((i*25)%240)/240.0}%)`;
        html = html.replace(re, `<span class="marker" data-marker="${x}" style="outline-color:${bg}; background-color:${bg}; color:#000">${x}</span>`);
      });
    }
    const editing = this.props.editing===p.id;
    return <div className={`${cls} lang-${p.lang} ${dirty}`}>
      <Paragraph p={p} editing={editing} html={html}
                 readOnly={this.props.readOnly}
                 markers={this.props.markers}
                 onStartEdit={this.props.onStartEdit}
                 onCancelEdit={this.props.onCancelEdit}
                 onSaveEdit={this.props.onSaveEdit}
                 onTextSelect={text => this.props.onTextSelection(p.id, text)}
                 onMarkerClick={this.props.onMarkerClick}
      />
    </div>
  }

  render() {
    const { revealPrevious, revealNext } = this.props;
    const [p2, p1] = this.props.paras;
    const chapterRow = this.props.showChapterTitle
                       ? this.renderChapterTitleRow(this.props.chapter, p1.seq)
                       : null;
    const speakerChange = this.props.readOnly
                          ? null : ()=>this.props.onSpeakerClicked(p1.seq);
    return <>
      {chapterRow}
      <section className={"row " + (this.props.isLast ? 'last-row' : '')
                                 + (this.props.needsWork ? ' needs-work':'')
                                 + (this.props.isPerfect ? ' is-perfect':'')}
               ref={el => this.rowDiv_ = el}>
        {this.renderParagraph(p1, 'right-column')}
        <div className="middle-column">
          <span className="seq-number">
            <span onClick={revealPrevious}
               className={"reveal-button "+(revealPrevious?'active':'')}>⮝</span>
            <span className="needs-work-toggle"
                  title={"Chapter " + this.props.chapter.ru + " (" + this.props.chapter.he + "), offset " + p1.offsetInChapter + ", " + (100*this.props.seqProgress).toFixed(1) + '%'}
                onClick={e=>e.ctrlKey
                              ? this.props.onToggleIsPerfect(p1)
                              : this.props.onToggleNeedsWork(p1)}>{p1.seq}</span>
            <span onClick={revealNext}
               className={"reveal-button "+(revealNext?'active':'')}>⮟</span>
          </span>
          <SpeakerLabel spid={p1.speaker}
                        changing={this.props.changingSpeaker}
                        nickname={this.props.speakerNickname}
                        onClick={speakerChange} />
        </div>
        {this.renderParagraph(p2, 'left-column')}
      </section>
    </>
  }
}
