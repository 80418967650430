import React from 'react';
import autoBind from 'react-autobind';
import './loader.css';

export default class Loader extends React.PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    return <div style={{width: '100vw', height:'100vh', display: 'flex', alignItems:'center', justifyContent:'center' }} >
    <div style={{color: "hsl(144, 40%, 50%)"}} className="la-ball-scale-multiple la-3x">
      <div></div>
      <div></div>
      <div></div>
      </div>
    </div>
  }
}