export function objSubset(obj, keys) {
  return keys.reduce((slice, key) => {
    if(key in obj)  slice[key] = obj[key];
    return slice;
  }, {});
}

export function objMap(obj, func) {
  return Object.keys(obj).reduce((m, key) => {
       const nv = func(key, obj[key]);
       if(nv !== null) m[key] = nv;
       return m;
     }, {});
}

export function objWithout(obj, key) {
  return objMap(obj, (k, v) => k==key ? null : v); // must not use === here "1"<>1
}


export function reorderItemInList(list, from, to) {
  const result = Array.from(list);
  const [removed] = result.splice(from, 1);
  result.splice(to, 0, removed);
  return result;
}

export function isObjEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function isNull(obj) {
  return obj === null || obj === undefined;
}

export function isEmpty(obj) {
  if(isNull(obj)) return true;
  if(typeof obj === 'object') return Object.keys(obj).length === 0;
  if(Array.isArray(obj)) return obj.length === 0;
  return obj === "";
}

export function escapeRegex(string) {
  return string.replace(/[\/\\^$*+?.()|[\]{}]/g, '\\$&');
}


// Expanding the regex to match diacritics and text-based formatting ques
// such as asteriks, e.g. we want to match "oo ba" on "foo *bar*"
const hebrewDiacritics = '\u0591-\u05C7';
const specialCharacters = '*{}';
const expandedChar = '[' + hebrewDiacritics + specialCharacters + ']';
const expandedSequence = expandedChar + '*';
const expandedCharRegexp = new RegExp(expandedChar, "u");

function expandChar(char)
{
  if(char === 'е') return '[её]';
  if(char === 'Е') return '[ЕЁ]';
  if(/[א-ת]/u.test(char)) return expandedSequence + char + expandedSequence;
  return char;
}

function expandWord(word) {
  //if(word.startsWith('\\') || word.startsWith('[')) return word;
  // if the original string contains diacritics, the user must have really meant to
  // match on them, so we're not expanding the regexp:
  //if(expandedCharRegexp.test(word)) return word;
  return word.split('').map(x => expandChar(x)).join('');
}

export function expandedRegexFromString(str) {
  return str.split(' ').map(x => expandWord(x)).join(' ');
}