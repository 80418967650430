import React from 'reactn';
import autoBind from 'react-autobind';
import docxLogo from './docx_icon.svg';

export default class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  onSearchChange(value) {
    this.dispatch.setTextFilter(value);
  }
          // <a href="/docx" className="docx-download">
          //   <img src={docxLogo} width="40px" />
          // </a>

  render() {
    const g = this.global;
    return <header>
        <input id="search-box"
              type="text"
              value={g.filters.text}
              onChange={e=>this.onSearchChange(e.target.value)}></input>
        <span className="match-stats">{g.matches.counts.individual>0? g.matches.counts.individual + " matches in ":''}{g.matches.counts.paragraphs} paragraphs</span>
        <div className="docx">
        </div>
        <div className="user">
          <span>{g.auth?.username} </span>
          <a href="" onClick={() => this.dispatch.loginRequired()}>(logout)</a>
        </div>
    </header>
  }
}