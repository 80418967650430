import { addReducers, setGlobal } from 'reactn';
import { expandedRegexFromString, isEmpty } from './utils';
import { fetchJson } from './network';
import { initPersist, clearPersistedState } from './persist';
import { parseParagraph } from './Paragraph';
import * as login from './login';

const initialState = auth => ({
  persist: ['areNetworkWritesPending',
  /* 'bookLoaded', 'langs',
  'paragraphs', 'paragraphOrder', 'chapters', 'chapterOrder', 'speakers', 'speakerOrder', 'footnotes' */
  ],

  auth,

  bookList: [],
  loadingBookList: false,
  loadingBook: false,
  bookLoaded: 0,

  title: {},
  langs: [],
  paragraphs: {}, // id => paragraph
  paragraphOrder: [], // each element is a pair of paragraph ids by language
  chapters: {},
  chapterOrder: [],
  speakers: {},
  speakerOrder: [],
  footnotes: {},
  markers: [],

  filters: {  
    text:    "", // plain string
    speaker: {}, // each value is a hash of filter values
    chapter: {}, // each value is a hash of filter values
    problems: {},
    highlights: {},
    needsWork: {}
  },

  matches: {
    paragraphs: [],  // indexes into paragraphOrder
    counts: {
      paragraphs: 0, // allows updating match count without changing the matche list,
      individual: 0, // to keep context after editing a paragraph
      speaker: {},
      chapter: {},
      problems: {},
      highlights: {},
      needsWork: {}
    }
  },

  editing: null,
  saving: false,
  changingSpeaker: {},
  areNetworkWritesPending: false,

  lastScrollTop: 0
});


export function initStore() {
  //setGlobal(initialState);
  initPersist(initialState(JSON.parse(localStorage.getItem("auth"))));
  registerReducers();
}


function registerReducers() {
  addReducers({
    networkAccess,
    loadBook,
    onBook,
    listBooks,
    onBookList,
    saveParagraphEdit,
    onParagraphSaved,
    setFilters,
    toggleFilter,
    setTextFilter,
    resetFilters,
    updateMatchCount,
    changeSpeaker,
    onSpeakerChanged,
    onNetworkError,
    toggleNeedsWork,
    toggleIsPerfect,
    changeParagraph,
    expandMatches,
    loginRequired,
    onLogin,
    addGlobalMarker,
    removeGlobalMarker
  });
}


function networkAccess(global, dispatch, params, callback, onerror) {
  fetchJson('/a', params,
            json => {
              if(callback) callback(json);
            },
            error => {
              if(error && error.status === 401) {
                dispatch.loginRequired();
              } else {
                if(onerror) onerror(error);
  //              dispatch.newNotification("Error in accessing " + script);
              }
            },
            global.auth?.token);
}


function loadBook(global, dispatch, id) {
  clearPersistedState();
  networkAccess(global, dispatch, { c: 'book', id },
                json => { dispatch.onBook(json); });
  return { bookLoaded: 0, loadingBook: true };
}

function onBook(global, dispatch, book) {
  dispatch.resetFilters();
  let chapter = null;
  const paras = book.paragraphs;
  for(const idlist of book.paragraphOrder) {
    if(paras[idlist[0]].seq in book.chapters) chapter = paras[idlist[0]].seq;
    for(const id of idlist) {
      paras[id] = parseParagraph(paras[id], global.filters.text);
      paras[id].chapterId = chapter;
      paras[id].offsetInChapter = paras[id].seq - chapter + 1;
    }
  }
  return { loadingBook:    false,
           title:          book.title,
           bookLoaded:     book.id,
           paragraphs:     book.paragraphs,
           paragraphOrder: book.paragraphOrder,
           chapters:       book.chapters,
           chapterOrder:   Object.keys(book.chapters).sort((a,b) => a-b).map(x=>1*x),
           speakers:       { ...book.speakers, "-1": { id:-1, nickname:'AUTHOR'} },
           speakerOrder:   [ -1, ...book.speakerOrder ],
           markers:        book.markers };
}

function listBooks(global, dispatch) {
  networkAccess(global, dispatch, { c: 'list-books' },
                json => { dispatch.onBookList(json); });
  return { loadingBookList: true };
}

function onBookList(global, dispatch, { bookList }) {
  return { bookList, loadingBookList: false }
}

function saveParagraphEdit(global, dispatch, pid, newstr, retry) {
  networkAccess(global, dispatch, { c: 'save-para', pid, str: newstr },
                json => dispatch.onParagraphSaved(json),
                error => dispatch.onNetworkError(pid));
  if(retry) return {};
  return {
    editing: null,
    ...changeParagraph(global, dispatch, pid, { str: newstr })
  }
}

function onParagraphSaved(global, dispatch, {id, str}) {
  return changeParagraph(global, dispatch, id, { str, dirty: false });
}

function onNetworkError(global, dispatch, pid) {
  return {
    areNetworkWritesPending: true,
    ...changeParagraph(global, dispatch, pid, { dirty: true })
  };
}

function toggleNeedsWork(global, dispatch, pid) {
  const p = global.paragraphs[pid];
  const needs_work = !p.needs_work;
  const is_perfect = needs_work ? false : p.is_perfect;
  const cnt = global.matches.counts.needsWork;
  cnt.all += (needs_work ? 1 : -1);
  networkAccess(global, dispatch, { c: 'para-needs-work', pid, needs_work });
  return {
    ...changeParagraph(global, dispatch, pid, { needs_work, is_perfect }),
    counts: { ...global.matches.counts, needsWork: cnt }
  }
}

function toggleIsPerfect(global, dispatch, pid) {
  const p = global.paragraphs[pid];
  const is_perfect = !p.is_perfect;
  const needs_work = is_perfect ? false : p.needs_work;
  networkAccess(global, dispatch, { c: 'para-is-perfect', pid, is_perfect });
  return changeParagraph(global, dispatch, pid, { is_perfect, needs_work });
}

function addGlobalMarker(global, dispatch, str) {
  const book = global.bookLoaded;
  networkAccess(global, dispatch, { c: 'add-global-marker', str, book });
  return {
    markers: global.markers.concat(str)
  };
}

function removeGlobalMarker(global, dispatch, str) {
  const book = global.bookLoaded;
  networkAccess(global, dispatch, { c: 'remove-global-marker', str, book });
  return {
    markers: global.markers.filter(x => x !== str)
  };
}

function changeParagraph(global, dispatch, pid, newvalues) {
  const p = { ...global.paragraphs[pid], ...newvalues };
  return {
    paragraphs: {
      ...global.paragraphs,
      [pid]: parseParagraph(p, null)
    }
  };
}


function setTextFilter(global, dispatch, textFilter)
{
  dispatch.setFilters({ text: isEmpty(textFilter) ? "" : textFilter});
}

function toggleFilter(global, dispatch, filterType, value)
{
  const newValue = value in global.filters[filterType] ? {} : { [value]: true };
  dispatch.setFilters({ [filterType]: newValue });

  if(filterType === "chapter") {
    const book = document.getElementById("book");
    if(newValue) {
      const top = book.scrollTop;
      book.scrollTo(0,0);
      window.scrollTo(0,0);
      return { lastScrollTop: top };
    } else {
      setTimeout(()=>book.scrollTo(0, parseInt(global.lastScrollTop)), 60);
      return { lastScrollTop: null };
    }
  }
}

function resetFilters(global, dispatch) {
  let filters = {};
  Object.entries(global.filters).forEach(
    ([filterType, val]) => { 
      filters[filterType] = (typeof val === 'object') ? {} : "" }
  );
  dispatch.setFilters(filters);
}

function setFilters(global, dispatch, filters)
{
  return matchingEngine(global, filters);
}

function updateMatchCount(global, dispatch)
{
  const m = matchingEngine(global, global.filters);
  return { matches: { ...global.matches, counts: m.matches.counts } };
}


function matchingEngine(global, newFilters)
{
  const filters = { ...global.filters, ...newFilters };
  const tags = ['speaker', 'chapter', 'problems', 'highlights', 'needsWork'];

  let matches = { paragraphs: [], counts: { paragraphs: 0, individual: 0 } };
  let isTagFilter = {};
  let isTagFiltered = false;
  tags.forEach(tag => { isTagFilter[tag] = !isEmpty(filters[tag]);
                        isTagFiltered = isTagFiltered || isTagFilter[tag];
                        matches.counts[tag] = {}; });

  let is_text_filter = !isEmpty(filters.text);
  let re = null;
  
  if(is_text_filter) {
    try {
      re = new RegExp(expandedRegexFromString(filters.text), "gisu");
    } catch { 
      re = null;
      is_text_filter = false;
    }
  }
  
  let chapter = null;
  for(let i=0; i<global.paragraphOrder.length; ++i) {
    const tuple = global.paragraphOrder[i];
    const p1 = global.paragraphs[tuple[0]];
    const seq = p1.seq;
    if(seq in global.chapters) chapter = seq;
    let num_matches = 0;
    const spid = p1.speaker || -1;
    if(isTagFilter.speaker && !(spid in filters.speaker))    continue;
    if(isTagFilter.chapter && !(chapter in filters.chapter)) continue;
    
    let problemsExist = false;
    let highlightsExist = false;
    let needsWorkExist = false;
    for(const pid of tuple) {
      const p = global.paragraphs[pid];
      if(is_text_filter) num_matches += (p.str.match(re) || []).length;
      problemsExist   = problemsExist   || p.problemsExist;
      highlightsExist = highlightsExist || p.highlightsExist;
      needsWorkExist  = needsWorkExist  || p.needs_work;
    }
    if(isTagFilter.problems && !problemsExist) continue;
    if(isTagFilter.highlights && !highlightsExist) continue;
    if(isTagFilter.needsWork && !needsWorkExist) continue;

    if(!is_text_filter || num_matches > 0) {
      matches.paragraphs.push(i);
      matches.counts.paragraphs++;
      matches.counts.individual += num_matches;
      matches.counts.speaker[spid]    = (matches.counts.speaker[spid]||0) + 1;
      matches.counts.chapter[chapter] = (matches.counts.chapter[chapter]||0) + 1;
      if(problemsExist) {
        matches.counts.problems['all'] = (matches.counts.problems['all']||0) + 1;
      }
      if(highlightsExist) {
        matches.counts.highlights['all'] = (matches.counts.highlights['all']||0) + 1;
      }
      if(needsWorkExist) {
        matches.counts.needsWork['all'] = (matches.counts.needsWork['all']||0) + 1;
      }
   }
  }
  return { filters, matches, changingSpeaker: {} };
}



function changeSpeaker(global, dispatch, seqlist, spid) {
  networkAccess(global, dispatch, { c: 'change-speaker',seqlist, spid,
                                    book: global.bookLoaded },
                json => dispatch.onSpeakerChanged(json));
}

function onSpeakerChanged(global, dispatch, {spid, pids}) {

  let paragraphs = global.paragraphs;
  let spcount      = global.matches.counts.speaker;
  for(const id of pids) {
    if(paragraphs[id].lang === 'ru') { // hack, we update counts only for 1 lang
      if(paragraphs[id].speaker) spcount[paragraphs[id].speaker]--;
      if(spid) spcount[spid]++;
    }
    paragraphs = { ...paragraphs, [id]: { ...paragraphs[id],
                                          speaker: spid<0?null:spid } };
  }

  return {
    changingSpeaker: {},
    paragraphs,
    matches: { ...global.matches,
               counts: { ...global.matches.counts, speaker: spcount } }
  }
}


function expandMatches(global, dispatch, idx) {
  return {
    matches: {
      ...global.matches,
      paragraphs: [...new Set(global.matches.paragraphs.concat(idx))].sort((a,b) => 1*a-1*b)
    }
  }
}

function loginRequired(global, dispatch) {
  localStorage.removeItem("auth");
  return initialState(null);
}


function onLogin(global, dispatch, auth, bookList) {
  localStorage.setItem("auth", JSON.stringify(auth));
  return { auth };
}