
export async function fetchJson(url, params, onResponse, onError, token) {
  const abortController = new AbortController();
  let headers =  {'Content-Type': 'application/json'};
  if(token) headers['X-YH-Auth'] = token;
  /*
  const mailData = JSON.parse(window.localStorage.getItem('mail')||'{}');
  if(mailData && mailData.auth) {
    headers['X-Token'] = mailData.auth;
  }*/
  //const script = '/msg/new/mail.cgi?' + scriptName;

  try {
        const response = await fetch(url,
                                  { method: "post",
                                    headers,
                                    body: JSON.stringify(params),
                                    signal: abortController.signal });
     if(response.ok) {
       const json = await response.json();
       if(onResponse) onResponse(json);
     } else {
       if(onError) onError(response);
     }
  }
  catch(e) {
    if(e.name === "AbortError") return;
    if(onError) onError(e);
  }
  return abortController;
}
