import React from 'reactn';
import autoBind from 'react-autobind';
import FilterTagList from './FilterTagList';

export default class ChapterFilters extends React.PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  renderChaptersFilterList() {
    const g = this.global;
    return <FilterTagList items={g.chapterOrder}
               nameFunc={ch => g.chapters[ch].ru}
               classFunc={ch => "chapter-filter"}
               statsFunc={ch => g.matches.counts.chapter[ch]}
               isSelectedFunc={ch => g.filters.chapter[ch]}
               onClick={ch => this.dispatch.toggleFilter('chapter', ch)} />
  }

  renderGeneralFilterList() {
    const g = this.global;
    return <FilterTagList items={['needsWork', 'highlights', 'problems']}
               nameFunc={id => id}
               classFunc={id => id + '-filter'}
               statsFunc={id => g.matches.counts[id]['all']}
               isSelectedFunc={id => g.filters[id]['all']}
               onClick={id => this.dispatch.toggleFilter(id, 'all')} />
  }



  render() {
    return <div className="filter-tag-list filter-chapters">
      {this.renderChaptersFilterList()}
      {this.renderGeneralFilterList()}
    </div>
  }
}