import React from 'react';
import autoBind from 'react-autobind';
import { expandedRegexFromString, isEmpty } from './utils';

export class Paragraph extends React.PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.editRef = React.createRef();
    this.state = { footnotePopup: null, top: null, left: null, right: null };
  }

  componentDidUpdate() {
    if(this.editRef.current) this.editRef.current.focus();
  }

  onDoubleClick(e) {
    const u = this.whatsUnderMouse(e);
//    if(u.highlight) this.deleteHighlight(u.highlight);
//    else {
      this.setState({footnotePopup: null});
      if(!this.props.editing) this.props.onStartEdit(this.props.p.id);
//    }
  }

  deleteHighlight(highlight) {
    const offset = 1*highlight;
    const str = this.props.p.str;
    if(str.substr(offset,1) === '{') { // safety check
      const end = str.indexOf('}', offset);
      if(end>offset) {
        const newStr = str.slice(0, offset)
                        + str.slice(offset+1, end)
                        + str.slice(end+1);
        if(newStr !== str) this.props.onSaveEdit(this.props.p.id, newStr);
      }
    }
  }

  whatsUnderMouseHelper(e, cls, data) {
    const el = e.target.closest(cls);
    return el && e.currentTarget.contains(el) ? el.getAttribute(data) : null;
  }

  whatsUnderMouse(e) {
    const footnote = this.whatsUnderMouseHelper(e, '.footnote', 'data-footnote');
    if(footnote) return { footnote };
    const highlight = this.whatsUnderMouseHelper(e, '.highlight', 'data-highlight');
    if(highlight) return { highlight };
    const marker = this.whatsUnderMouseHelper(e, '.marker', 'data-marker');
    if(marker) return { marker };
    return {};
  }

  onClick(e) {
    const u = this.whatsUnderMouse(e);
    if(u.footnote) this.onDoubleClick(e);
    if(u.marker && e.altKey) {
      this.props.onMarkerClick(this.props.p.id, u.marker);
    }
  }

  onMouseOver(e) {
    const u = this.whatsUnderMouse(e);
    if(u.footnote) {
      this.setState({footnotePopup: u.footnote,
                     top: e.target.offsetTop,
                    // left: e.target.offsetLeft+e.target.offsetWidth
                     right: e.target.parentNode.offsetWidth - e.target.offsetLeft
                            + 12 
                     });
    } else {
      if(this.state.footnotePopup) this.setState({footnotePopup: null});
    }
  }

  onMouseUp(e) {
    // black magic that detects whether the mouse is clicked twice at the same
    // spot, meaning, a double-click. In this case we want to edit, not to mark.
    // https://stackoverflow.com/questions/880512/prevent-text-selection-after-double-click
   // if(e.detail === 2) { e.preventDefault(); return; }
    if(e.altKey && window.getSelection()
       && window.getSelection().toString().length > 0) {
      this.props.onTextSelect(window.getSelection().toString());
    }
  }

  resizeTextarea(e) {
    e.target.style.height=e.target.scrollHeight+"px";
  }

  renderFootnotePopup() {
    const ft = this.state.footnotePopup;
    if(!ft) return null;
    const style= { top: this.state.top, right: this.state.right };
    return <div style={style} className="footnote-popup">{this.props.p.footnotes[ft]}</div>;
  }

  render() {
    const { p, editing, html, readOnly } = this.props;
    if(editing) {
      const saveFunc = e => {
        if(p.str !== e.target.value) this.props.onSaveEdit(p.id, e.target.value);
        else this.props.onCancelEdit();
      }
      return <div className="paragraph">
        <textarea  ref={this.editRef}
                   onInput={this.resizeTextarea}
                   onFocus={this.resizeTextarea}
                   onBlur={saveFunc}
                   defaultValue={p.str}></textarea>
      </div>
    }
    const actions = readOnly ? {}
                    : { onDoubleClick: e => this.onDoubleClick(e),
                        onClick:       e => this.onClick(e),
                        onMouseUp:     e => this.onMouseUp(e) };
    return <><div className="paragraph"
             {...actions}
             onMouseOver={e => this.onMouseOver(e)}
             onMouseOut={() => this.setState({footnotePopup: null})}
             onBlur={() => this.setState({footnotePopup: null})}
             dangerouslySetInnerHTML={{__html: html}}>
     </div>{this.renderFootnotePopup()}</>
  }
}


function footnoteStringLabel(ft, footnotes) {
  const cls = footnotes[ft] ? 'footnote-exists' : 'footnote-missing';
  const sft = ft.replace(/[\]\[\.]/gs,'');
  return `<span class="footnote ${cls}" data-footnote="${ft}">${sft}</span>`;
}
  
function parseFootnotes(str) {
  const flist = str.split(/\s*(\[[^\]\.]*?\])\s*/s);
  let footnotes = {};
  for(let i=1; i<flist.length; i += 2) {
    footnotes[flist[i]] = flist[i+1];
  }
  return footnotes;
}

function highlightText(text) {
  return text.replace(/{([^}]+?)}/g, (match, p1, offset) =>
                      `<span class="highlight" data-highlight="${offset}">${p1}</span>`)
             .replace(/(\s\s+|\s[.,?!](?!\.\.)|[,?!][^\[\s"״'׳».?!\)]|(?<!\.\.)\.[^\s.\[)'׳"״»]|[{}])(?![^<]*>)/g, '<span class="extraspace">$1</span>')
             .replace(/[*]+([^*\]\[]+?)[*]+/g, "<em>$1</em>")
             .replace(/^=\n+(.+)\n+=$/mgsu, '<span class="blockquote">$1</span>');
             // you won't believe me, but the dot in .+ does NOT match all
             // characters, unless you add a "s" and "u" modifiers
}

export function parseParagraph(p, textFilter) {
  let text = p.str;

  if(text === "" || text === null || text === undefined) {
    return { ...p, html: "<p>(((empty)))</p>" };
  }

  const ftlist = text.match(/===(.*)/s);
  const footnotes = ftlist ? parseFootnotes(ftlist[0]) : {};
  text = highlightText(text.replace(/===.*/s,''));
  let flist = text.split(/(\[[^\]]*?\])/s);
  for(let i=0; i<flist.length; ++i) {
    if(i % 2 === 1) flist[i] = footnoteStringLabel(flist[i], footnotes);
    else {
      if(!isEmpty(textFilter)) {
        // the negative lookahead in our regexp is to make sure we are searching 
        // for text that is outside any html tags that we've added about when
        // highlighting the text
        let re = null;
        try {
          re = new RegExp('('+expandedRegexFromString(textFilter)+')(?![^<]*>)',
                          "gisu");
        } catch { re = null; }
        if(re) {
          const matches = flist[i].split(re) || [flist[i]];
          flist[i] = "";
          for(let m=0; m<matches.length; ++m) {
            const mstr = matches[m];
            if(m % 2 === 0) flist[i] += mstr;
            else flist[i] += '<span class="highlighted">' + mstr + '</span>';
          }
        }
      } else {
        //flist[i] = highlightText(flist[i]);
      }
    }
  }
  text = flist.join("");

  const problemsExist = text.match(/<[^>]*(extraspace|footnote-missing)[^>]*>/);
  const highlightsExist = p.str.match(/[{}]/);

  return { ...p, html: '<p>' + text + '</p>',
           footnotes, problemsExist, highlightsExist };
}
